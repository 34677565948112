import React, { useState, useEffect, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import UserContext from "../../context/UserContext";
import axios from "axios";
import { SRLWrapper } from 'simple-react-lightbox';
import SimpleReactLightbox from 'simple-react-lightbox';
import { Button } from '@material-ui/core';
import LoadingBackdrop from '../feedback/LoadingBackdrop';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#70ad45",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 400,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export default function BiobaseExperiment(props) {
  const classes = useStyles();
  const { userData } = useContext(UserContext);

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    headers: {
        "x-auth-token" : userData.token
    }
    });

  const [ biobase, setBiobase ] = useState();
  const [ bioExpId, setBioExpId ] = useState();
  const [ loading, setLoading ] = useState(true);
  const [ uploadedBy, setUploadedBy ] = useState();

  useEffect(() => { 
    const loadBiobase = async () => {  
        if (!biobase && localStorage.getItem(props.bioExpId)) {
            try {
                setBioExpId(localStorage.getItem(props.bioExpId));
                const biobaseRes = await axiosClient.get("/biobase/getBiobase", {params: {bio_exp_id: localStorage.getItem(props.bioExpId)}});
                setBiobase(biobaseRes.data);
                const uploadedByRes = await axiosClient.get("/users/getUserUploaded", {params: {userId: biobaseRes.data.userId}});
                setUploadedBy(uploadedByRes.data);
                setLoading(false);
            } catch (error) {
                
            }
        }
    };
    window.scroll(0,0);
    loadBiobase();
  }, []);

  const downloadCsv = async () => {
    window.open(process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.csv_file);
  };

  const srlOptions = {
    settings : {
        downloadedFileName : bioExpId,
    },
  }

  return (biobase ? (
    <>      <LoadingBackdrop open={loading} />
            <Grid item md={12}>
            {uploadedBy ? (<h4>Uploaded by: {uploadedBy.firstname} {uploadedBy.lastname}</h4>) : (<></>)}
            <Button variant="contained" color="secondary" onClick={(e) => downloadCsv(e)} fullWidth={true} >Download CSV File</Button>
            <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table" >
                <TableBody>
                    <StyledTableRow key="bio_exp_id">
                        <StyledTableCell component="th" scope="row">
                            Bio Experiment ID:
                        </StyledTableCell>
                        <StyledTableCell >{biobase ? biobase.bio_exp_id : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="phd_id">
                        <StyledTableCell component="th" scope="row">
                            Name:
                        </StyledTableCell>
                        <StyledTableCell >{biobase ? biobase.phd_id : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="lab_id">
                        <StyledTableCell component="th" scope="row">
                            Lab:
                        </StyledTableCell>
                        <StyledTableCell >{biobase ? biobase.lab_id : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="date">
                        <StyledTableCell component="th" scope="row">
                            Date:
                        </StyledTableCell>
                        <StyledTableCell >{biobase ? biobase.date : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="bioink_id">
                        <StyledTableCell component="th" scope="row">
                            Bioink ID:
                        </StyledTableCell>
                        <StyledTableCell >{biobase ? biobase.bioink_id : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="bioink_id">
                        <StyledTableCell component="th" scope="row">
                            Cell Type:
                        </StyledTableCell>
                        <StyledTableCell >{biobase ? biobase.cellType : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="bioink_id">
                        <StyledTableCell component="th" scope="row">
                            Morphology Reporter:
                        </StyledTableCell>
                        <StyledTableCell >{biobase ? biobase.morphologyReporter : "----"}</StyledTableCell>
                    </StyledTableRow>
                    {/* <StyledTableRow key="compos_text">
                        <StyledTableCell component="th" scope="row">
                            Composition:
                        </StyledTableCell>
                        <StyledTableCell >{biobase ? biobase.compos_text : "----"}</StyledTableCell>
                    </StyledTableRow> */}
                    <StyledTableRow key="bio_notes">
                        <StyledTableCell component="th" scope="row">
                            Notes:
                        </StyledTableCell>
                        <StyledTableCell >{biobase ? biobase.bio_notes : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="bioer_id">
                        <StyledTableCell component="th" scope="row">
                            Printer:
                        </StyledTableCell>
                        <StyledTableCell >{biobase ? biobase.printer_id : "----"}</StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
            </TableContainer>
            <Divider />
            <div></div>
            <SimpleReactLightbox>
            <SRLWrapper options={srlOptions}>
                <TableContainer component={Paper}>
                    <h2>Alginate (Negative Control):</h2>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell align="center">Day 1</StyledTableCell>
                            <StyledTableCell align="center">Day 3</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow key="vitality">
                                <StyledTableCell component="th" scope="row">
                                    Vitality
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <a href={biobase.img_vital_alg_d1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_vital_alg_d1 : "images/no_image.jpg"}>
                                        <img src={biobase.img_vital_alg_d1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_vital_alg_d1 : "/images/no_image.jpg"} 
                                        alt="Vitality Day 1" width="200" height="auto" />
                                    </a>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <a href={biobase.img_vital_alg_d3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_vital_alg_d3 : "images/no_image.jpg"}>
                                        <img src={biobase.img_vital_alg_d3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_vital_alg_d3 : "/images/no_image.jpg"} 
                                        alt="Vitality Day 3" width="200" height="auto" />
                                    </a>
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="vitality_values">
                                <StyledTableCell component="th" scope="row">
                                    Values
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.vital_alg_d1_value+" ± "+biobase.vital_alg_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.vital_alg_d3_value+" ± "+biobase.vital_alg_d3_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                        <TableHead>
                        <TableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell align="center">Day 1</StyledTableCell>
                            <StyledTableCell align="center">Day 3</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow key="morph">
                                <StyledTableCell component="th" scope="row">
                                Morphology
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <a href={biobase.img_morph_alg_d1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_morph_alg_d1 : "images/no_image.jpg"}>
                                        <img src={biobase.img_morph_alg_d1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_morph_alg_d1 : "/images/no_image.jpg"} 
                                        alt="Morphology NIH3T3 Day 1" width="200" height="auto" />
                                    </a>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <a href={biobase.img_morph_alg_d3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_morph_alg_d3 : "images/no_image.jpg"}>
                                        <img src={biobase.img_morph_alg_d3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_morph_alg_d3 : "/images/no_image.jpg"} 
                                        alt="Morphology NIH3T3 Day 3" width="200" height="auto" />
                                    </a>
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="morph_round">
                                <StyledTableCell component="th" scope="row">
                                   Roundness
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.round_alg_d1_aver+" ± "+biobase.round_alg_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.round_alg_d3_aver+" ± "+biobase.round_alg_d3_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="morph_aspect">
                                <StyledTableCell component="th" scope="row">
                                    Aspect Ratio
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.aspect_alg_d1_aver+" ± "+biobase.aspect_alg_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.aspect_alg_d3_aver+" ± "+biobase.aspect_alg_d3_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="morph_soli">
                                <StyledTableCell component="th" scope="row">
                                    Solidarity
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.soli_alg_d1_aver+" ± "+biobase.soli_alg_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.soli_alg_d3_aver+" ± "+biobase.soli_alg_d3_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                        <TableHead>
                        <TableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell align="center">Day 1</StyledTableCell>
                            <StyledTableCell align="center">Day 3</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow key="proliferation">
                                <StyledTableCell component="th" scope="row">
                                Proliferation
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <a href={biobase.img_prolif_alg_d1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_prolif_alg_d1 : "images/no_image.jpg"}>
                                        <img src={biobase.img_prolif_alg_d1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_prolif_alg_d1 : "/images/no_image.jpg"} 
                                        alt="Proliferation Day 1" width="200" height="auto" />
                                    </a>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <a href={biobase.img_prolif_alg_d3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_prolif_alg_d3 : "images/no_image.jpg"}>
                                        <img src={biobase.img_prolif_alg_d3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_prolif_alg_d3 : "/images/no_image.jpg"} 
                                        alt="Proliferation Day 3" width="200" height="auto" />
                                    </a>
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="proliferation_values">
                                <StyledTableCell component="th" scope="row">
                                    Values
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.prolif_alg_d1_value+" ± "+biobase.prolif_alg_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.prolif_alg_d3_value+" ± "+biobase.prolif_alg_d3_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                        </TableBody>  
                    </Table>
                    <h2>GelMA (Positive Control):</h2>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell align="center">Day 1</StyledTableCell>
                            <StyledTableCell align="center">Day 3</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow key="vitality">
                                <StyledTableCell component="th" scope="row">
                                    Vitality
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <a href={biobase.img_vital_gel_d1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_vital_gel_d1 : "images/no_image.jpg"}>
                                        <img src={biobase.img_vital_gel_d1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_vital_gel_d1 : "/images/no_image.jpg"} 
                                        alt="Vitality Day 1" width="200" height="auto" />
                                    </a>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <a href={biobase.img_vital_gel_d3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_vital_gel_d3 : "images/no_image.jpg"}>
                                        <img src={biobase.img_vital_gel_d3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_vital_gel_d3 : "/images/no_image.jpg"} 
                                        alt="Vitality Day 3" width="200" height="auto" />
                                    </a>
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="vitality_values">
                                <StyledTableCell component="th" scope="row">
                                    Values
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.vital_gel_d1_value+" ± "+biobase.vital_gel_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.vital_gel_d3_value+" ± "+biobase.vital_gel_d3_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                        <TableHead>
                        <TableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell align="center">Day 1</StyledTableCell>
                            <StyledTableCell align="center">Day 3</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow key="morph">
                                <StyledTableCell component="th" scope="row">
                                Morphology
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <a href={biobase.img_morph_gel_d1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_morph_gel_d1 : "images/no_image.jpg"}>
                                        <img src={biobase.img_morph_gel_d1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_morph_gel_d1 : "/images/no_image.jpg"} 
                                        alt="Morphology NIH3T3 Day 1" width="200" height="auto" />
                                    </a>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <a href={biobase.img_morph_gel_d3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_morph_gel_d3 : "images/no_image.jpg"}>
                                        <img src={biobase.img_morph_gel_d3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_morph_gel_d3 : "/images/no_image.jpg"} 
                                        alt="Morphology NIH3T3 Day 3" width="200" height="auto" />
                                    </a>
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="morph_round">
                                <StyledTableCell component="th" scope="row">
                                   Roundness
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.round_gel_d1_aver+" ± "+biobase.round_gel_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.round_gel_d3_aver+" ± "+biobase.round_gel_d3_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="morph_aspect">
                                <StyledTableCell component="th" scope="row">
                                    Aspect Ratio
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.aspect_gel_d1_aver+" ± "+biobase.aspect_gel_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.aspect_gel_d3_aver+" ± "+biobase.aspect_gel_d3_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="morph_soli">
                                <StyledTableCell component="th" scope="row">
                                    Solidarity
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.soli_gel_d1_aver+" ± "+biobase.soli_gel_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.soli_gel_d3_aver+" ± "+biobase.soli_gel_d3_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                        <TableHead>
                        <TableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell align="center">Day 1</StyledTableCell>
                            <StyledTableCell align="center">Day 3</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow key="proliferation">
                                <StyledTableCell component="th" scope="row">
                                Proliferation
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <a href={biobase.img_prolif_gel_d1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_prolif_gel_d1 : "images/no_image.jpg"}>
                                        <img src={biobase.img_prolif_gel_d1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_prolif_gel_d1 : "/images/no_image.jpg"} 
                                        alt="Proliferation Day 1" width="200" height="auto" />
                                    </a>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <a href={biobase.img_prolif_gel_d3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_prolif_gel_d3 : "images/no_image.jpg"}>
                                        <img src={biobase.img_prolif_gel_d3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_prolif_gel_d3 : "/images/no_image.jpg"} 
                                        alt="Proliferation Day 3" width="200" height="auto" />
                                    </a>
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="proliferation_values">
                                <StyledTableCell component="th" scope="row">
                                    Values
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.prolif_gel_d1_value+" ± "+biobase.prolif_gel_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.prolif_gel_d3_value+" ± "+biobase.prolif_gel_d3_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                        </TableBody>  
                    </Table>
                    <h2>Project Specific Bioink:</h2>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell align="center">Day 1</StyledTableCell>
                            <StyledTableCell align="center">Day 3</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow key="vitality">
                                <StyledTableCell component="th" scope="row">
                                    Vitality
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <a href={biobase.img_vital_psb_d1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_vital_psb_d1 : "images/no_image.jpg"}>
                                        <img src={biobase.img_vital_psb_d1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_vital_psb_d1 : "/images/no_image.jpg"} 
                                        alt="Vitality Day 1" width="200" height="auto" />
                                    </a>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <a href={biobase.img_vital_psb_d3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_vital_psb_d3 : "images/no_image.jpg"}>
                                        <img src={biobase.img_vital_psb_d3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_vital_psb_d3 : "/images/no_image.jpg"} 
                                        alt="Vitality Day 3" width="200" height="auto" />
                                    </a>
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="vitality_values">
                                <StyledTableCell component="th" scope="row">
                                    Values
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.vital_psb_d1_value+" ± "+biobase.vital_psb_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.vital_psb_d3_value+" ± "+biobase.vital_psb_d3_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                        <TableHead>
                        <TableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell align="center">Day 1</StyledTableCell>
                            <StyledTableCell align="center">Day 3</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow key="morph">
                                <StyledTableCell component="th" scope="row">
                                Morphology
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <a href={biobase.img_morph_psb_d1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_morph_psb_d1 : "images/no_image.jpg"}>
                                        <img src={biobase.img_morph_psb_d1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_morph_psb_d1 : "/images/no_image.jpg"} 
                                        alt="Morphology NIH3T3 Day 1" width="200" height="auto" />
                                    </a>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <a href={biobase.img_morph_psb_d3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_morph_psb_d3 : "images/no_image.jpg"}>
                                        <img src={biobase.img_morph_psb_d3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_morph_psb_d3 : "/images/no_image.jpg"} 
                                        alt="Morphology NIH3T3 Day 3" width="200" height="auto" />
                                    </a>
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="morph_round">
                                <StyledTableCell component="th" scope="row">
                                   Roundness
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.round_psb_d1_aver+" ± "+biobase.round_psb_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.round_psb_d3_aver+" ± "+biobase.round_psb_d3_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="morph_aspect">
                                <StyledTableCell component="th" scope="row">
                                    Aspect Ratio
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.aspect_psb_d1_aver+" ± "+biobase.aspect_psb_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.aspect_psb_d3_aver+" ± "+biobase.aspect_psb_d3_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="morph_soli">
                                <StyledTableCell component="th" scope="row">
                                    Solidarity
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.soli_psb_d1_aver+" ± "+biobase.soli_psb_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.soli_psb_d3_aver+" ± "+biobase.soli_psb_d3_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                        <TableHead>
                        <TableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell align="center">Day 1</StyledTableCell>
                            <StyledTableCell align="center">Day 3</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow key="proliferation">
                                <StyledTableCell component="th" scope="row">
                                Proliferation
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <a href={biobase.img_prolif_psb_d1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_prolif_psb_d1 : "images/no_image.jpg"}>
                                        <img src={biobase.img_prolif_psb_d1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_prolif_psb_d1 : "/images/no_image.jpg"} 
                                        alt="Proliferation Day 1" width="200" height="auto" />
                                    </a>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <a href={biobase.img_prolif_psb_d3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_prolif_psb_d3 : "images/no_image.jpg"}>
                                        <img src={biobase.img_prolif_psb_d3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_prolif_psb_d3 : "/images/no_image.jpg"} 
                                        alt="Proliferation Day 3" width="200" height="auto" />
                                    </a>
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="proliferation_values">
                                <StyledTableCell component="th" scope="row">
                                    Values
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.prolif_psb_d1_value+" ± "+biobase.prolif_psb_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.prolif_psb_d3_value+" ± "+biobase.prolif_psb_d3_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                        </TableBody>  
                    </Table>
                        {/* <TableHead>
                        <TableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell align="center">Day 1</StyledTableCell>
                            <StyledTableCell align="center">Day 7</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow key="morph_hum">
                                <StyledTableCell component="th" scope="row">
                                Morphology {biobase ? biobase.human_cell_type : "----"} Reporter Cells
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <a href={biobase.img_morph_hum_d1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_morph_hum_d1 : "images/no_image.jpg"}>
                                        <img src={biobase.img_morph_hum_d1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_morph_hum_d1 : "/images/no_image.jpg"} 
                                        alt={"Morphology "+biobase.human_cell_type+" Day 1"} width="200" height="auto" />
                                    </a>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <a href={biobase.img_morph_hum_d7!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_morph_hum_d7 : "images/no_image.jpg"}>
                                        <img src={biobase.img_morph_hum_d7!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+bioExpId+"--"+biobase.img_morph_hum_d7 : "/images/no_image.jpg"} 
                                        alt={"Morphology "+biobase.human_cell_type+" Day 7"} width="200" height="auto" />
                                    </a>
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="morph_hum_">
                                <StyledTableCell component="th" scope="row">
                                    Area (µm²)
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.hum_area_d1_aver+" ± "+biobase.hum_area_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.hum_area_d7_aver+" ± "+biobase.hum_area_d7_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="morph_hum_perimeter">
                                <StyledTableCell component="th" scope="row">
                                    Perimeter (µm)
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.hum_peri_d1_aver+" ± "+biobase.hum_peri_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.hum_peri_d7_aver+" ± "+biobase.hum_peri_d7_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="morph_hum_majax">
                                <StyledTableCell component="th" scope="row">
                                    Major Axis (µm)
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.hum_majax_d1_aver+" ± "+biobase.hum_majax_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.hum_majax_d7_aver+" ± "+biobase.hum_majax_d7_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="morph_hum_angle">
                                <StyledTableCell component="th" scope="row">
                                    Angle (°)
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.hum_angle_d1_aver+" ± "+biobase.hum_angle_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.hum_angle_d7_aver+" ± "+biobase.hum_angle_d7_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="morph_hum_circul">
                                <StyledTableCell component="th" scope="row">
                                    Circularity
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.hum_circul_d1_aver+" ± "+biobase.hum_circul_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.hum_circul_d7_aver+" ± "+biobase.hum_circul_d7_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="morph_hum_aspect">
                                <StyledTableCell component="th" scope="row">
                                    Aspect Ratio
                                </StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.hum_aspect_d1_aver+" ± "+biobase.hum_aspect_d1_stdv : "----"}</StyledTableCell>
                                <StyledTableCell align="center">{biobase ? biobase.hum_aspect_d7_aver+" ± "+biobase.hum_aspect_d7_stdv : "----"}</StyledTableCell>
                            </StyledTableRow>
                        </TableBody> */}
                </TableContainer>
            </SRLWrapper>
            </SimpleReactLightbox>
            </Grid>
    </>) : (<></>)
  );
}
