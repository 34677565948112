import React, { useState, useEffect, useContext, useHistory } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import LoadingBackdrop from '../feedback/LoadingBackdrop';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import UserContext from "../../context/UserContext";
import axios from "axios";
import _ from 'underscore';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { CardActionArea } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { LineChart, LinePlot } from '@mui/x-charts/LineChart';
import { MarkPlot } from '@mui/x-charts/LineChart';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { ChartsGrid } from '@mui/x-charts/ChartsGrid';
import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip';
import { ResponsiveChartContainer } from '@mui/x-charts';

const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
    paperList: {
      height: 200,
      overflow: 'auto',
    },
    title: {
        fontSize: 14,
      },
      pos: {
        marginBottom: 12,
      },
    root: {
        marginBottom: 10
    }
  }));
  
  const sortByInputs = [
    {
        value: 'ink_exp_id',
        label: 'Exp. ID'
    },
    {
        value: 'date',
        label: 'Date'
    },
    {
        value: 'ink_id',
        label: 'Ink ID'
    },
];

const units = [
    {xAxis: ' rad/s', yAxis: ' Pa'},
    {xAxis: ' rad/s', yAxis: ' Pa'},
    {xAxis: ' s', yAxis: ' Pa.s'},
    {xAxis: ' s', yAxis: ' Pa.s'},
    {xAxis: ' %', yAxis: ' Pa'},
    {xAxis: ' s', yAxis: ' Pa'},
    {xAxis: ' rad/s', yAxis: ' Pa'},
    {xAxis: ' s', yAxis: ' Pa.s'},
    {xAxis: ' 1/s', yAxis: ' Pa.s'},
    {xAxis: ' s', yAxis: ' Pa'},
  ];

const scales = [
    {xAxis: 'log', yAxis: 'log'},
    {xAxis: 'log', yAxis: 'log'},
    {xAxis: 'linear', yAxis: 'log'},
    {xAxis: 'linear', yAxis: 'log'},
    {xAxis: 'log', yAxis: 'log'},
    {xAxis: 'linear', yAxis: 'log'},
    {xAxis: 'log', yAxis: 'log'},
    {xAxis: 'linear', yAxis: 'log'},
    {xAxis: 'log', yAxis: 'log'},
    {xAxis: 'linear', yAxis: 'log'},
  ];

const xAxisDataKeys = ["", "angular_frequency", "interval_time", "interval_time", "shear_strain", "interval_time", "angular_frequency", "interval_time", "shear_rate", "interval_time" ];
const yAxisDataKey1s = ["", "storage_modulus", "viscosity", "viscosity", "storage_modulus", "storage_modulus", "storage_modulus", "viscosity", "viscosity", "storage_modulus"];
const yAxisDataKey2s = ["", "loss_modulus", "", "", "loss_modulus", "loss_modulus", "loss_modulus", "", "", "loss_modulus"];

export default function RheobaseComparisonX() {
    const classes = useStyles();
    const { userData } = useContext(UserContext);

    const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            "x-auth-token" : userData.token
        }
    });

    const [ rheobase, setRheobase ] = useState();
    const [ rheobases, setRheobases ] = useState([]);
    const [ listRheobases, setListRheobases ] = useState([]);
    const [ showRheobases, setShowRheobases ] = useState([]);
    const [ addedRheobases, setAddedRheobases ] = useState([]);
    
    const [ currentInterval, setCurrentInterval ] = useState(1);

    // Data for chart
    const [ xAxisData, setXAxisData ] = useState([]);
    const [ series, setSeries ] = useState([]);
    
    const [ loadingComplete, setLoadingComplete ] = useState(false);

    const [ interval1color, setInterval1Color ] = useState("primary");
    const [ interval2color, setInterval2Color ] = useState();
    const [ interval3color, setInterval3Color ] = useState();
    const [ interval4color, setInterval4Color ] = useState();
    const [ interval5color, setInterval5Color ] = useState();
    const [ interval6color, setInterval6Color ] = useState();
    const [ interval7color, setInterval7Color ] = useState();
    const [ interval8color, setInterval8Color ] = useState();
    const [ interval9color, setInterval9Color ] = useState();

    const [ xAxisReverse, setXAxisReverse ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    
    let searchKey = "";
    const [ sortBy, setSortBy ] = useState("");

    useEffect(() => {
        const loadRheobases = async () => {
            setLoading(true);
          try {
              const rheobasesRes = await axiosClient.get("rheobase/getAll");
              setRheobases(rheobasesRes.data);
              setListRheobases(rheobasesRes.data);
              setShowRheobases(rheobasesRes.data);
          } catch (err) {
              console.log('Error in getting rheobase metadata')
          }
          setLoading(false);
        };  
        loadRheobases();
      }, []);

    const reloadChartData = (interval) => {
        addedRheobases.forEach(rheobase => {
            getRheobaseRows(rheobase, interval);
            console.log('Reloaded rheobase: '+rheobase.ink_exp_id);
        });
    }

    const getRheobaseRows = async (rheobase, interval) => {
        setLoading(true);
        /* var xAxisDataTemp = [...xAxisData];
        var yAxisData1Temp = [...yAxisData1];
        var yAxisData2Temp = [...yAxisData2]; */
        var xAxisDataTemp = [];
        var yAxisData1Temp = [];
        var yAxisData2Temp = [];
        const rheobaseRowsRes = await axiosClient.get("rheobase/getRheobaseRows", {params: {ink_exp_id: rheobase.ink_exp_id, interval_no: interval}});
        rheobaseRowsRes.data.forEach(row => {
            xAxisDataTemp.push(row[xAxisDataKeys[currentInterval]]);
            yAxisData1Temp.push(row[yAxisDataKey1s[currentInterval]]);
            yAxisData2Temp.push(row[yAxisDataKey2s[currentInterval]]);
        });
        setXAxisData(xAxisDataTemp);
        /* setYAxisData1(yAxisData1Temp);
        setYAxisData2(yAxisData2Temp); */
        //build xData
        
        //build series
        var seriesS = [...series];
        seriesS.push({
            data: yAxisData1Temp,
            label: yAxisDataKey1s[currentInterval]+'('+rheobase.ink_exp_id+')',
            type: 'line',
            yAxisId: 'yAxis'
        });
        if (yAxisDataKey2s[currentInterval] === '') {
            //nothing
        } else {
            seriesS.push({
                data: yAxisData2Temp,
                label: yAxisDataKey2s[currentInterval]+'('+rheobase.ink_exp_id+') ',
                type: 'line',
                yAxisId: 'yAxis'
            });
        }
        setSeries(seriesS);
        setLoading(false);
    }

    const addRheobase = (rheobase) => {
        setLoading(true);
        let added = addedRheobases;
        added.push(rheobase);
        setAddedRheobases(added);
        let difference = listRheobases.filter(x => !added.includes(x));
        setListRheobases(difference);
        setShowRheobases(difference);
        getRheobaseRows(rheobase, currentInterval);
        setLoading(false);
    }

    const removeRheobase = (rheobase) => {
        let listRheobase = listRheobases;
        listRheobase.push(rheobase);
        setListRheobases(listRheobase);
        let difference = rheobases.filter(x => !listRheobase.includes(x));
        setAddedRheobases(difference);
    }

    const handleClick = (e, interval) => {
        console.info('You clicked the Interval ', interval);
        setLoading(true);
        setXAxisData([]);
        setSeries([]);
        setAddedRheobases([]);
        setListRheobases(rheobases);
        setShowRheobases(rheobases)
        setCurrentInterval(interval);
        setLoading(false);
      };

    const resetIntervalChips = () => {
        setInterval1Color("default");
        setInterval2Color("default");
        setInterval3Color("default");
        setInterval4Color("default");
        setInterval5Color("default");
        setInterval6Color("default");
        setInterval7Color("default");
        setInterval8Color("default");
        setInterval9Color("default");
      }

    function sleep(time){
        return new Promise((resolve)=>setTimeout(resolve,time));
    }

    const filterRheobases = () => {
        const results = [];
        listRheobases.forEach(rheobase => {
            if (rheobase.ink_exp_id.toUpperCase().includes(searchKey.toUpperCase()) || rheobase.name.toUpperCase().includes(searchKey.toUpperCase()) || 
            rheobase.date.toUpperCase().includes(searchKey.toUpperCase()) || rheobase.ink_id.toUpperCase().includes(searchKey.toUpperCase()) || 
            rheobase.location.toUpperCase().includes(searchKey.toUpperCase()) || rheobase.device.toUpperCase().includes(searchKey.toUpperCase())) {
                results.push(rheobase);
            }
        });
        setShowRheobases(results);
    };
    
    const sortRheobases = (sortKey) => {
        setShowRheobases(_.sortBy(showRheobases, sortKey));
    };

    const rheobasesList = (allRheobases) => (
        <Paper className={classes.paperList}>
        <List className={classes.root}>
            {allRheobases?allRheobases.map((arheobase) => {
                const labelId = `${arheobase}`;

                return (
                <ListItem key={arheobase.ink_exp_id} role='listitem' dense button onClick={(e) => setRheobase(arheobase)} 
                    selected={rheobase? arheobase.ink_exp_id === rheobase.ink_exp_id : false}>
                    
                    <ListItemText id={labelId} primary={arheobase.ink_exp_id} />
                    <ListItemSecondaryAction>
                    <IconButton color="primary" aria-label="add" onClick={(e) => addRheobase(arheobase)}>
                        <AddIcon />
                    </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                );
            }):<></>}
        </List>
        </Paper>
    );

  return (
    <>
    {
        (!userData.token) ?
        (<div>You are not authorised to access this section. Please login.</div>) :
        (   
            <div style={{margin: 20}}>
                {/* <h1>Rheobase Comparison</h1> */}
                <LoadingBackdrop open={loading} />
                <Grid container maxWidth="xl">
                    <Grid item xs={3}>
                        <Typography variant="h5" className={classes.available}>
                            Rheobase Comparison
                        </Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={8}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="rheobase_search"
                                    label="Search Rheobase"
                                    name="rheobase_search"
                                    onChange={(e) => {searchKey=e.target.value; filterRheobases();}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    select
                                    id="rheobase_sort"
                                    name="rheobase_sort"
                                    label="Sort by"
                                    value={sortBy}
                                    onChange={(e) => {setSortBy(e.target.value); sortRheobases(e.target.value);}}
                                >
                                    {sortByInputs.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        {rheobasesList(showRheobases)}
                        <Typography variant="h5" className={classes.available}>
                            Experiment Metadata
                        </Typography>
                        <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            {/* <TableHead>
                            <TableRow>
                                <TableCell>Dessert (100g serving)</TableCell>
                                <TableCell align="right">Calories</TableCell>
                            </TableRow>
                            </TableHead> */}
                            <TableBody>
                                <TableRow key="key">
                                <TableCell component="th" scope="row">
                                    Experiment ID:
                                </TableCell>
                                <TableCell align="right">{rheobase? rheobase.ink_exp_id : "----"}</TableCell>
                                </TableRow>
                                <TableRow key="key">
                                <TableCell component="th" scope="row">
                                    Ink ID:
                                </TableCell>
                                <TableCell align="right">{rheobase? rheobase.ink_id : "----"}</TableCell>
                                </TableRow>
                                <TableRow key="key">
                                <TableCell component="th" scope="row">
                                    Name:
                                </TableCell>
                                <TableCell align="right">{rheobase? rheobase.name : "----"}</TableCell>
                                </TableRow>
                                <TableRow key="key">
                                <TableCell component="th" scope="row">
                                    Date:
                                </TableCell>
                                <TableCell align="right">{rheobase? rheobase.date : "----"}</TableCell>
                                </TableRow>
                                <TableRow key="key">
                                <TableCell component="th" scope="row">
                                    Location:
                                </TableCell>
                                <TableCell align="right">{rheobase? rheobase.location : "----"}</TableCell>
                                </TableRow>
                                <TableRow key="key">
                                <TableCell component="th" scope="row">
                                    Device:
                                </TableCell>
                                <TableCell align="right">{rheobase? rheobase.device : "----"}</TableCell>
                                </TableRow>
                                <TableRow key="key">
                                <TableCell component="th" scope="row">
                                    Geometry:
                                </TableCell>
                                <TableCell align="right">{rheobase? rheobase.geometry : "----"}</TableCell>
                                </TableRow>
                                <TableRow key="key">
                                <TableCell component="th" scope="row">
                                    Notes:
                                </TableCell>
                                <TableCell align="right">{rheobase? rheobase.notes : "----"}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={9}>
                        <Container component="main">
                            <Grid container>
                                {addedRheobases.map((card) => (
                                    <Grid item key={card.heading} xs={12} sm={3} lg={3}>
                                        <Card className={classes.root} onClick={() => setRheobase(card)}>
                                            <CardActionArea>
                                            <CardContent>
                                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                    {/* <IconButton color="primary" aria-label="add">
                                                        <DeleteIcon />
                                                    </IconButton> */}
                                                    {card.ink_exp_id}
                                                </Typography>
                                                <Typography className={classes.pos} color="textSecondary">
                                                    {card.ink_id}
                                                </Typography>
                                                <Typography variant="body2" component="p">
                                                    {card.name}
                                                </Typography>
                                            </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>))
                                }
                            </Grid>
                                <Checkbox checked={xAxisReverse} onChange={(e) => setXAxisReverse(!xAxisReverse)}></Checkbox>Reverse X Axis 
                            {<LineChart
                            xAxis={[{ data: xAxisData, scaleType: scales[currentInterval].xAxis, label: units[currentInterval].xAxis, reverse: xAxisReverse }]}
                            yAxis={[{ id: 'yAxis', scaleType: scales[currentInterval].yAxis, label: units[currentInterval].yAxis}]}
                            series={series}
                            width={1200}
                            height={675}
                            grid={{ vertical: true, horizontal: true }}
                            leftAxis= 'yAxis'
                            rightAxis= 'yAxis'
                            margin={{ top:100}}
                            slotProps={{
                                legend: {
                                    direction: 'row',
                                    position: { vertical: 'top', horizontal: 'middle' },
                                    padding: -9,
                                },
                            }}
                            />}
                            {/* <ResponsiveChartContainer
                                xAxis={[{ id: 'xAxis', data: xAxisData, scaleType: scales[currentInterval].xAxis, label: units[currentInterval].xAxis }]}
                                yAxis={[{ id: 'yAxis', scaleType: scales[currentInterval].yAxis, label: units[currentInterval].yAxis}]}
                                series={series}
                                width={800}
                                height={500}
                                grid={{ vertical: true, horizontal: true }}
                                leftAxis= 'yAxisScale'>
                                <LinePlot />
                                <MarkPlot />
                                <ChartsXAxis label="X axis" />
                                <ChartsYAxis label="Y axis" axisId="yAxis" />
                                <ChartsGrid vertical horizontal />
                                <ChartsTooltip />
                            </ResponsiveChartContainer> */}
                        </Container>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Chip avatar={<Avatar>1</Avatar>} label="Frequency sweep" color={interval1color} onClick={(e) => {resetIntervalChips(); setInterval1Color("primary"); handleClick(e,1)}} />
                            </Grid>
                            <Grid item>
                                <Chip avatar={<Avatar>2</Avatar>} label="Preconditioning" color={interval2color} onClick={(e) => {resetIntervalChips(); setInterval2Color("primary"); handleClick(e,2)}} />
                            </Grid>
                            <Grid item>
                                <Chip avatar={<Avatar>3</Avatar>} label="Resting step" color={interval3color} onClick={(e) => {resetIntervalChips(); setInterval3Color("primary"); handleClick(e,3)}} />
                            </Grid>
                            <Grid item>
                                <Chip avatar={<Avatar>4</Avatar>} label="Amplitude sweep" color={interval4color} onClick={(e) => {resetIntervalChips(); setInterval4Color("primary"); handleClick(e,4)}} />
                            </Grid>
                            <Grid item>
                                <Chip avatar={<Avatar>5</Avatar>} label="1st recovery" color={interval5color} onClick={(e) => {resetIntervalChips(); setInterval5Color("primary"); handleClick(e,5)}} />
                            </Grid>
                            <Grid item>
                                <Chip avatar={<Avatar>6</Avatar>} label="Frequency sweep" color={interval6color} onClick={(e) => {resetIntervalChips(); setInterval6Color("primary"); handleClick(e,6)}} />
                            </Grid>
                            <Grid item>
                                <Chip avatar={<Avatar>7</Avatar>} label="Resting step" color={interval7color} onClick={(e) => {resetIntervalChips(); setInterval7Color("primary"); handleClick(e,7)}} />
                            </Grid>
                            <Grid item>
                                <Chip avatar={<Avatar>8</Avatar>} label="Flow point" color={interval8color} onClick={(e) => {resetIntervalChips(); setInterval8Color("primary"); handleClick(e,8)}} />
                            </Grid>
                            <Grid item>
                                <Chip avatar={<Avatar>9</Avatar>} label="2nd recovery" color={interval9color} onClick={(e) => {resetIntervalChips(); setInterval9Color("primary"); handleClick(e,9)}} />
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
        )
        }
    </>
  );
}